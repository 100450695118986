<template>
  <div class="main-content">
    <VideoProvide>
      <slot />
    </VideoProvide>
  </div>
</template>
<script>
import VideoProvide from './VideoProvide.vue'

export default {
  name: 'WrapperVideo',
  components: { VideoProvide },
  data () {
    return {}
  }
}
</script>
