<template>
  <div class="main-content">
    <Navbar/>
    <slot />
  </div>
</template>
<script>
// import Echo from 'laravel-echo'
import appUtils from '../utils/appUtils'
import Navbar from '../components/layouts/Navbar.vue'

// window.io = require('socket.io-client')

export default {
  name: 'AppLayout',
  components: { Navbar },
  computed: {
  },
  data () {
    return {
      appUtils
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
  }
}
</script>
