<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import { useConnectionOptions } from '../mixins/VideoCore/useConnectionOptions'
import { useLocalTracks } from '../mixins/VideoCore/useLocalTracks'
import { useRoom } from '../mixins/VideoCore/useRoom'

export default {
  name: 'VideoProvide',
  mixins: [
    useLocalTracks,
    useConnectionOptions,
    useRoom('localTracks', 'connectionOptions')
  ],
  data () {
    const videoProvide = {}

    Object.defineProperties(videoProvide, {
      room: {
        enumerable: true,
        get: () => this.room
      },
      localTracks: {
        enumerable: true,
        get: () => this.localTracks
      },
      isConnecting: {
        enumerable: true,
        get: () => this.isConnecting
      },
      isAcquiringLocalTracks: {
        enumerable: true,
        get: () => this.isAcquiringLocalTracks
      }
    })

    return {
      videoProvide
    }
  },
  provide () {
    return {
      videoProvide: this.videoProvide,
      onError: this.onErrorCallback,
      getLocalVideoTrack: this.getLocalVideoTrack,
      connect: this.connect,
      removeLocalVideoTrack: this.removeLocalVideoTrack,
      removeLocalAudioTrack: this.removeLocalAudioTrack,
      getAudioAndVideoTracks: this.getAudioAndVideoTracks
    }
  },
  mounted () {
    // this.getLocalVideoTrack()
    this.getAudioAndVideoTracks()
    this.handleRoomDisconnection(
      this.room,
      this.onErrorCallback,
      this.removeLocalAudioTrack,
      this.removeLocalVideoTrack
    )
    this.restartAudioTrackOnDeviceChange(this.audioTrack)
  },
  watch: {
    audioTrack: {
      handler (data) {
        this.restartAudioTrackOnDeviceChange(data)
      },
      deep: true
    }
  },
  computed: {},
  methods: {
    onErrorCallback (error) {
      console.log(`ERROR: ${error.message}`, error)
    },
    handleRoomDisconnection (
      room,
      onError,
      removeLocalAudioTrack,
      removeLocalVideoTrack,
      isSharingScreen,
      toggleScreenShare,
      destroyed
    ) {
      if (room) {
        const onDisconnected = (_, error) => {
          if (error) {
            onError(error)
          }

          removeLocalAudioTrack()
          removeLocalVideoTrack()
          if (isSharingScreen) {
            toggleScreenShare()
          }
        }

        if (!destroyed) {
          room.on('disconnected', onDisconnected)
        } else {
          room.off('disconnected', onDisconnected)
        }
      }
    },
    restartAudioTrackOnDeviceChange (audioTrack, destroyed) {
      const data = audioTrack || this.audioTrack
      const handleDeviceChange = () => {
        if (data?.mediaStreamTrack.readyState === 'ended') {
          data.restart({})
        }
      }

      if (!destroyed) {
        navigator.mediaDevices.addEventListener(
          'devicechange',
          handleDeviceChange
        )
      } else {
        navigator.mediaDevices.removeEventListener(
          'devicechange',
          handleDeviceChange
        )
      }
    }
  },
  destroyed () {
    this.handleRoomDisconnection(
      this.room,
      this.onErrorCallback,
      this.removeLocalAudioTrack,
      this.removeLocalVideoTrack,
      null,
      null,
      true
    )
    this.restartAudioTrackOnDeviceChange(this.audioTrack, true)
  }
}
</script>
