import '@/assets/css/element-variables.scss'
import 'vue-toast-notification/dist/theme-sugar.css'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import './assets/css/style.css'
import './assets/css/style.scss'
import './assets/css/mdtStyle.css'

import App from './App.vue'
import AppLayout from './layouts/AppLayout.vue'
import CallLayout from './layouts/CallLayout.vue'
import RequestFactory from './api/RequestFactory'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueProgressBar from 'vue-progressbar'
import VueSweetalert2 from 'vue-sweetalert2'
import VueToast from 'vue-toast-notification'
import WrapperVideo from './layouts/WrapperVideo.vue'
import enMessage from './lang/en.json'
import frMessage from './lang/fr.json'
import moment from 'moment'
import router from './router'
import store from './store'
import viMessage from './lang/vi.json'
import ElementUI from 'element-ui'
import langEl from 'element-ui/lib/locale/lang/vi'
import locale from 'element-ui/lib/locale'

locale.use(langEl)

Vue.use(ElementUI)

// component
Vue.component('app-layout', AppLayout)
Vue.component('call-layout', CallLayout)
Vue.component('video-layout', WrapperVideo)
Vue.use(VueI18n)

// config
Vue.config.productionTip = false
if (process.env.NODE_ENV !== 'development') {
  Vue.config.devtools = false
} else {
  Vue.config.devtools = true
}

window.axios = require('axios')
Vue.prototype.$rf = new RequestFactory()

const multiUploading = Vue.observable({ multiUploading: [] })
Object.defineProperty(Vue.prototype, '$multiUploading', {
  get () {
    return multiUploading.multiUploading
  },

  set (value) {
    multiUploading.multiUploading = value
  }
})

Vue.use(VueProgressBar, {
  color: '#476DD6',
  failedColor: 'red',
  height: '2px'
})

Vue.use(VueToast, {
  position: 'top'
})
Vue.use(VueSweetalert2)
window.$toast = {}

window.$toast.noti = (status, message) => {
  if (status) {
    Vue.$toast.open({
      message: message,
      type: 'success'
      // all other options
    })
  } else {
    Vue.swal.fire({
      html: message,
      icon: 'error'
    })
  }
}

const echo = Vue.observable({ echo: null })

Object.defineProperty(Vue.prototype, '$echo', {
  get () {
    return echo.echo
  },

  set (value) {
    echo.echo = value
  }
})

const conectedEcho = Vue.observable({ conectedEcho: null })

Object.defineProperty(Vue.prototype, '$conectedEcho', {
  get () {
    return conectedEcho.conectedEcho
  },

  set (value) {
    conectedEcho.conectedEcho = value
  }
})

const user = Vue.observable({ user: null })

Object.defineProperty(Vue.prototype, '$user', {
  get () {
    return user.user
  },

  set (value) {
    user.user = value
  }
})

const targetRoute = Vue.observable({ targetRoute: '' })

Object.defineProperty(Vue.prototype, '$targetRoute', {
  get () {
    return targetRoute.targetRoute
  },

  set (value) {
    targetRoute.targetRoute = value
  }
})

const lang = Vue.observable({ lang: '' })

Object.defineProperty(Vue.prototype, '$lang', {
  get () {
    return lang.lang
  },

  set (value) {
    lang.lang = value
  }
})

Vue.prototype.moment = moment
window.moment = moment
global.jQuery = require('jquery')
var $ = global.jQuery
window.$ = $

const messages = {
  vi: viMessage,
  en: enMessage,
  fr: frMessage
}
const i18n = new VueI18n({
  locale: window.localStorage.lang ? window.localStorage.lang : 'vi',
  messages,
  fallbackLocale: 'vi',
  silentFallbackWarn: true
})
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
