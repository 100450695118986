<template>
  <div class="main-content">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'CallLayout',
  components: {},
  computed: {},
  data () {
    return {}
  },
  watch: {},
  mounted () {},
  methods: {}
}
</script>
